/* eslint-disable react/prop-types */
import { StatGroup, Stat, StatLabel, StatNumber, Box } from "@chakra-ui/react";
import { useState } from "react";
import {
  Text,
  Input,
  Button,
  Image,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";

import Cashout from "./Cashout";

const AccountDashboard = ({ user, handleLogout, setUser, fetchData }) => {
  const [editing, setEditing] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const toast = useToast();


  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleEditProfile = () => {
    setEditing(true);
  };

  const handleSaveProfile = async () => {
    try {
      const token = localStorage.getItem("jwt");
      const formData = new FormData();

      // Append user information and file if selected
      formData.append("userInfo", JSON.stringify(formData));
      if (selectedFile) {
        formData.append("file", selectedFile);
      }

      // Call to profile-upload endpoint with JWT for authentication
      const response = await fetch(`${import.meta.env.VITE_APP_API_URL}/profile-upload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Note: Content-Type is not set when using FormData
        },
        body: formData
      });

      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to update profile');
      }

      setEditing(false);
      setUser(responseData.user); // Assuming the API returns updated user data
      toast({
        position: 'top',
        title: "Profile updated.",
        description: "Your profile was successfully updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating profile:", error);
      toast({
        position: 'top',
        title: "Error updating profile.",
        description: error.toString(),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };




  const handleCancelProfile = async () => {
    setEditing(false);
  };


  if (!user) {
    return <Text>Loading...</Text>;
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Box>
      <FormLabel>Profile</FormLabel>
      <StatGroup>
        <Stat
          p={5}
          borderRadius="10px"
          mr={3}
          mb={3}
          bg="#f9f9f9"
        >
          <StatLabel>Golflink #</StatLabel>
          <StatNumber>{user.Golflinknumber}</StatNumber>
        </Stat>
        <Stat
          p={5}
          borderRadius="10px"
          mb={3}
          bg="#f9f9f9"
        >
          <StatLabel>Email</StatLabel>
          <StatNumber>{capitalizeFirstLetter(user.email)}</StatNumber>
        </Stat>
      </StatGroup>
      <StatGroup>
        <Stat
          p={5}
          borderRadius="10px"
          mr={3}
          mb={3}
          bg="#f9f9f9"
        >
          <StatLabel>Name</StatLabel>
          <StatNumber>{capitalizeFirstLetter(user.name)}</StatNumber>
        </Stat>
        <Stat
          p={5}
          borderRadius="10px"
          mb={3}
          bg="#f9f9f9"
        >
          <StatLabel>Surname</StatLabel>
          <StatNumber>{capitalizeFirstLetter(user.surname)}</StatNumber>
        </Stat>
      </StatGroup>
      <StatGroup>
        <Stat
          p={5}
          borderRadius="10px"
          mr={3}
          mb={3}
          bg="#f9f9f9"
        >
          <StatLabel>Golf Club</StatLabel>
          <StatNumber>{capitalizeFirstLetter(user.golfclub)}</StatNumber>
        </Stat>
        <Stat
          p={5}
          borderRadius="10px"
          mb={3}
          bg="#f9f9f9"
        >
          <StatLabel>State</StatLabel>
          <StatNumber>{capitalizeFirstLetter(user.state)}</StatNumber>
        </Stat>
      </StatGroup>
      <Stat
        p={5}
        borderRadius="10px"
        mb={3}
        bg="#f9f9f9"
      >
        {user.userverification ? (
          <StatLabel>Image submitted</StatLabel>
        ) : (
          <>
            <StatLabel>Verification</StatLabel>
            <StatNumber>Photo of your handicap or golflink # to get verified</StatNumber>
          </>
        )}
        <StatNumber> {user.userverification ? (
          <Image maxW="200px" />
        ) : (
          <Text></Text>
        )}{editing && (
          <FormControl>
            <Input type="file" onChange={handleFileChange} accept="image/*" pt={1} pl={1} bgColor='white' className="" />
          </FormControl>
        )}</StatNumber>
      </Stat>
      {editing ? (
        <>
          <div className="pb-40">
            <Button onClick={handleSaveProfile} className="ml-auto w-full vgolf-height vgolf-button mb-3">
              Save
            </Button>
            <Button onClick={handleCancelProfile} className="ml-auto w-full vgolf-height vgolf-logout-button">
              Cancel
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="pb-32">
            <Button onClick={handleEditProfile} className="ml-auto w-full vgolf-height vgolf-button mb-3">
              Upload Verification
            </Button>
            {user.cashout ? (
              <Cashout user={user} setUser={setUser} fetchData={fetchData} />
            ) : (
              <></>
            )}
            <Button onClick={handleLogout} className="ml-auto w-full vgolf-height vgolf-logout-button">
              Log out
            </Button>
          </div>
        </>
      )
      }


    </Box >
  );
};

export default AccountDashboard;
