import { useState, useEffect } from "react";
import { Text } from "@chakra-ui/react";

const EventCountdown = ({ start, end }) => {
  const [timeUntilStart, setTimeUntilStart] = useState("");
  const [timeUntilEnd, setTimeUntilEnd] = useState("");

  useEffect(() => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const updateCountdown = () => {
      const now = new Date();
      const startDiff = startDate - now;
      const endDiff = endDate - now;

      if (startDiff > 0) {
        const days = Math.floor(startDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((startDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((startDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((startDiff % (1000 * 60)) / 1000);

        setTimeUntilStart(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      } else {
        setTimeUntilStart("Event has started");
      }

      if (endDiff > 0) {
        const days = Math.floor(endDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((endDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((endDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((endDiff % (1000 * 60)) / 1000);

        setTimeUntilEnd(`${hours}h ${minutes}m ${seconds}s`);
      } else {
        setTimeUntilEnd("Event has ended");
      }
    };

    updateCountdown();
    const timer = setInterval(updateCountdown, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [start, end]);

  return (
    <>
      <Text marginTop={1}>Competition Results in {timeUntilEnd}</Text>
    </>
  );
};

export default EventCountdown;
