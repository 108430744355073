import { Box, Button, Flex, Heading, Image, TabPanel, TabPanels, Tabs, Text, VStack, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, CardBody, Card, Stack, Divider, CardFooter, CloseButton } from "@chakra-ui/react";
import { FaShoppingCart, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { useState, useEffect } from "react";
import create from "zustand";
import { persist } from 'zustand/middleware';
import PropTypes from 'prop-types';

// Categories for tabs
const categories = ["All", "Cards", "Bags", "Electronics"];

const useStore = create(persist(
    (set, get) => ({
        cart: [],
        points: 0,
        isCheckoutOpen: false, // Adding isCheckoutOpen to the Zustand store
        addToCart: (item) => {
            set((state) => ({
                cart: [...state.cart, item]
            }));
            get().toast({
                position: 'top',
                title: "Item added to cart",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        },
        removeFromCart: (itemId) => {
            const itemIndex = get().cart.findIndex((i) => i.id === itemId);
            if (itemIndex === -1) return; // Exit if item is not found

            const item = get().cart[itemIndex];
            set((state) => {
                // Create new cart array without the removed item
                const updatedCart = [
                    ...state.cart.slice(0, itemIndex),
                    ...state.cart.slice(itemIndex + 1)
                ];
                const newPoints = state.points + item.price; // Update points by adding back the price of the removed item

                // Close the checkout modal if the cart is now empty
                const isCheckoutStillOpen = updatedCart.length > 0 ? state.isCheckoutOpen : false;

                // Return the updated state
                return {
                    cart: updatedCart,
                    points: newPoints,
                    isCheckoutOpen: isCheckoutStillOpen
                };
            });

            get().toast({
                position: 'top',
                title: "Item removed from cart",
                description: `${item.name} has been removed from your cart.`,
                status: "info",
                duration: 3000,
                isClosable: true,
            });
        },
        clearCart: () => {
            set((state) => ({
                cart: [],
                points: state.points + state.cart.reduce((acc, item) => acc + item.price, 0)
            }));
            get().setIsCheckoutOpen(false); // Now it's part of Zustand store
            get().toast({
                position: 'top',
                title: "Cart cleared",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        },
        setIsCheckoutOpen: (isOpen) => {
            set({ isCheckoutOpen: isOpen });
        },
        toast: () => { } // Placeholder for toast function
    }),
    {
        name: 'shopping-cart-storage', // name of the item in the storage
        getStorage: () => localStorage // define the storage type
    }
));


const Index = ({ user, setUser, fetchData }) => {
    const [products, setProducts] = useState([]);
    const [sortPrice, setSortPrice] = useState(false);
    const [sortAsc, setSortAsc] = useState(true);
    const [showMatchPoints, setShowMatchPoints] = useState(false);
    const { cart, addToCart, removeFromCart, clearCart, isCheckoutOpen, setIsCheckoutOpen } = useStore();
    const toast = useToast();
    const totalCostCheckout = cart.reduce((acc, item) => acc + item.price, 0);

    const fetchProductData = async () => {
        try {
            const token = localStorage.getItem("jwt");
            if (token) {
                const response = await fetch(`${import.meta.env.VITE_APP_API_URL}/products?populate=*`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                // Define the base URL for images
                const baseURL = import.meta.env.VITE_APP_LIVE_URL
                // Transform the data to a simpler format for easier use in the component
                const formattedProducts = data.data.map(product => ({
                    id: product.id,
                    name: product.attributes.name,
                    description: product.attributes.description,
                    price: product.attributes.price,
                    category: product.attributes.category,
                    image: product.attributes.image.data
                        ? baseURL + product.attributes.image.data.attributes.formats.medium.url
                        : '/default-image.png' // Use medium format if available, otherwise default image
                }));
                setProducts(formattedProducts);
            }
        } catch (error) {
            console.error("Error fetching product data:", error);
            toast({
                position: 'top',
                title: "Error fetching products",
                description: "Unable to fetch products. Please check your connection and try again.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };



    useEffect(() => {
        fetchProductData();
    }, []);

    const handleCheckout = async () => {
        const totalCost = cart.reduce((acc, item) => acc + item.price, 0);
        if (user.points < totalCost) {
            toast({
                position: 'top',
                title: "Insufficient Points",
                description: `You need ${totalCost} points to purchase these items but only have ${user.points} points.`,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            const response = await fetch(`${import.meta.env.VITE_APP_API_URL}/purchase-product`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwt')}`,  // Ensure JWT is used if applicable
                },
                body: JSON.stringify({
                    productType: cart,
                    userInfo: user,
                }),
            });

            const responseData = await response.json();
            if (!response.ok) {
                throw new Error(responseData.message || 'Failed to purchase card');
            }

            // Assume successful transaction here
            setIsCheckoutOpen(false);
            clearCart();
            toast({
                position: 'top',
                title: "Purchase successful",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            setUser({ ...user, points: responseData.newPoints }); // Update user points in state
            fetchData(); // Refresh data if necessary
        } catch (error) {
            console.error("Error buying card:", error);
            toast({
                position: 'top',
                title: "Error buying card",
                description: error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }

    };

    useEffect(() => {
        useStore.setState({ points: user.points, toast });
    }, [user.points, toast]);

    const handleSort = () => {
        if (!sortPrice) {
            setSortPrice(true); // Enable sorting by price
            setSortAsc(true);   // Start in ascending order
        } else {
            setSortAsc(!sortAsc); // Toggle between ascending and descending
        }
    };

    const toggleShowMatchPoints = () => {
        setShowMatchPoints(!showMatchPoints);
    };

    const filteredProducts = (category) => {
        let filtered = products.filter(item => category === "All" ? true : item.category === category);
        if (showMatchPoints) {
            filtered = filtered.filter(item => item.price <= user.points);
        }
        return filtered;
    };

    const sortedProducts = (items) => {
        if (sortPrice) {
            return [...items].sort((a, b) => {
                if (sortAsc) return a.price - b.price;
                return b.price - a.price;
            });
        }
        return items;
    };

    return (
        <>
            {cart.length > 0 && (
                <>
                    <div className="checkout-wrapper">
                        <Button className="vgolf-logout-button vgolf-height checkout-button" leftIcon={<FaShoppingCart />} onClick={() => setIsCheckoutOpen(true)}>Checkout ({cart.length})</Button>
                    </div>
                </>
            )}
            <div className="flex items-center justify-between space-x-5 mb-3">
                {/* <Button className="vgolf-height vgolf-grey" onClick={toggleShowMatchPoints}>
                    {showMatchPoints ? "Show All Products" : `Sort by Points`}
                </Button> */}
                <Button className="vgolf-height vgolf-grey" leftIcon={sortAsc ? <FaSortAmountDown /> : <FaSortAmountUp />} onClick={handleSort}>
                    Sort by Price
                </Button>
            </div>
            <div className="p-0">
                <div className="p-0">
                    <Tabs colorScheme='gray'>
                        <div className="flex justify-center w-full">
                            {/* <TabList>
                                {categories.map(category => (
                                    <>

                                        <Tab key={category} className="vgolf-grey-tab">{category}</Tab>

                                    </>
                                ))}
                            </TabList> */}
                        </div>
                        <TabPanels >
                            {categories.map(category => (
                                <TabPanel key={category} >
                                    <VStack className="m-0">
                                        {sortedProducts(filteredProducts(category)).map(item => (
                                            <Card key={item.id} mb='2' className="bg-card">
                                                <CardBody>
                                                    <Image
                                                        src={item.image || '/default-image.png'} // Provide a default image if none is available
                                                        alt={item.name}
                                                        borderRadius='lg'
                                                        className="w-full"
                                                    />
                                                    <Stack mt='3' spacing='3'>
                                                        <Heading size='md'>{item.name}</Heading>
                                                        <Text>
                                                            {item.description}
                                                        </Text>
                                                    </Stack>
                                                </CardBody>
                                                <Divider />
                                                <CardFooter>
                                                    <Button
                                                        onClick={() => addToCart(item)}
                                                        variant='solid'
                                                        className="vgolf-button vgolf-height"
                                                        isDisabled={totalCostCheckout + item.price > user.points}
                                                    >
                                                        Redeem {item.price} Virtual Golf Balls
                                                    </Button>
                                                </CardFooter>
                                            </Card>
                                        ))}
                                    </VStack>
                                </TabPanel>
                            ))}
                        </TabPanels>

                    </Tabs>
                </div>

                <Modal isOpen={isCheckoutOpen} onClose={() => setIsCheckoutOpen(false)} size='xl'>
                    <ModalOverlay
                        backdropFilter='blur(5px)'
                    />
                    <ModalContent>
                        <ModalHeader>Checkout</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {cart.map((item, index) => (
                                <div
                                    key={index}
                                    direction={{ base: 'column', sm: 'row' }}
                                    overflow='hidden'
                                    variant='unstyled'
                                    className="bg-[#f9f9f9] mb-2"
                                >
                                    <div className="cart">
                                        <div className="">

                                            <Image
                                                boxSize='100px'
                                                className="rounded-md ml-4"
                                                objectFit='contain'
                                                src={item.image}
                                                alt={item.image}
                                            />
                                        </div>
                                        <div className="cart-box">
                                            <div className="cart-name font-semibold">{item.name}</div>
                                            <div className="cart-price">
                                                {item.price} Virtual Golf Balls
                                            </div>
                                        </div>
                                        <div className="">
                                            <Button variant='unstyled' onClick={() => removeFromCart(item.id)} className="modal-close">
                                                <CloseButton />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </ModalBody>
                        {cart.length > 0 && (
                            <>
                                <Text className="ml-4 mr-4">You&apos;re on the verge of redeeming {totalCostCheckout} Virtual Golf Balls from your account for this transaction. Please confirm your decision by clicking Confirm, or if you wish to cancel, click Cancel.</Text>
                                <Box p={4}>
                                    <Flex justifyContent="space-between" mb={4}>
                                        <Text fontWeight="bold">Virtual Golf Balls:</Text>
                                        <Text>{user.points} Virtual Golf Balls</Text>
                                    </Flex>
                                    <Flex justifyContent="space-between" mb={4}>
                                        <Text fontWeight="bold">Total Cost:</Text>
                                        <Text>{totalCostCheckout} Virtual Golf Balls</Text>
                                    </Flex>
                                    <Button onClick={clearCart} mb={4} className="vgolf-logout-button vgolf-height">
                                        Empty Cart
                                    </Button>
                                    <ModalFooter padding={0}>
                                        <Button onClick={handleCheckout} mr={3} className="vgolf-button vgolf-height" isDisabled={totalCostCheckout > user.points}>
                                            Confirm
                                        </Button>
                                        <Button mr={0} onClick={() => setIsCheckoutOpen(false)} className="vgolf-button-cancel vgolf-height">
                                            Cancel
                                        </Button>
                                    </ModalFooter>
                                </Box>
                            </>
                        )}
                    </ModalContent>
                </Modal>
            </div>
        </>
    );
};

export default Index;

Index.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        points: PropTypes.string,
    }).isRequired,
};