import { useState, useEffect } from "react";
import {
    Button,
    Flex,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useToast,
    Text,
} from "@chakra-ui/react";
import create from "zustand";
import { persist } from "zustand/middleware";
import PropTypes from 'prop-types';

const useStore = create(persist(
    (set) => ({
        points: 0,
        cashoutOpen: false,
        toast: () => { }, // Placeholder for toast function
        setCashoutOpen: (isOpen) => {
            set({ cashoutOpen: isOpen });
        }
    }),
    {
        name: "cashout-storage", // name of the item in the storage
        getStorage: () => localStorage // define the storage type
    }
));

const Index = ({ user, setUser, fetchData }) => {
    const [quantity, setQuantity] = useState();
    const { points, setCashoutOpen, cashoutOpen } = useStore();
    const toast = useToast();

    const handleCashout = async () => {
        const cashoutAmount = quantity * 3.5;

        if (quantity <= 0 || isNaN(quantity)) {
            toast({
                position: 'top',
                title: "Cannot Cashout",
                description: "Please enter a valid quantity to cashout.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        if (quantity > user.points) {
            toast({
                position: 'top',
                title: "Insufficient Balls",
                description: "You do not have enough Virtual Golf Balls for this transaction.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        // Here you would implement the cashout logic, like an API call
        try {
            const response = await fetch(`${import.meta.env.VITE_APP_API_URL}/purchase-cashout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwt')}`,  // Ensure JWT is used if applicable
                },
                body: JSON.stringify({
                    amount: cashoutAmount,
                    qty: quantity,
                    userInfo: user,
                }),
            });

            const responseData = await response.json();
            if (!response.ok) {
                throw new Error(responseData.message || 'Failed to cashout');
            }

            toast({
                position: 'top',
                title: "Cashout Successful",
                description: `You have cashed out $${cashoutAmount}.`,
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            setCashoutOpen(false);
            setUser({ ...user, points: responseData.newPoints }); // Update user points in state
            fetchData(); // Refresh data if necessary
        } catch (error) {
            console.error("Error buying card:", error);
            toast({
                position: 'top',
                title: "Error buying card",
                description: error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        useStore.setState({ points: user.points, toast });
    }, [user.points, toast]);

    const setMaxPoints = () => {
        setQuantity(user.points); // Set the input to the maximum points available
    };

    return (
        <>
            {user.points > 0 && (
                <Button onClick={() => setCashoutOpen(true)} className="ml-auto w-full vgolf-height vgolf-cashout-button">
                    Cashout
                </Button>
            )}

            <Modal isOpen={cashoutOpen} onClose={() => setCashoutOpen(false)} size="md">
                <ModalOverlay backdropFilter="blur(5px)" />
                <ModalContent>
                    <ModalHeader>Cashout Virtual Golf Balls</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex direction="column" gap="20px">
                            <Text mb="8px">Enter the quantity of Virtual Golf Balls to cash out:</Text>
                            <Flex gap="10px">
                                <Input
                                    placeholder="Quantity"
                                    type="number"
                                    value={quantity}
                                    onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
                                />
                                <Button className="max-button" onClick={setMaxPoints}>Max</Button>
                            </Flex>
                            <Text fontSize="lg" fontWeight="bold">
                                Total Cashout: ${quantity ? quantity * 3.5 : 0}
                            </Text>
                        </Flex>
                    </ModalBody>
                    {user.points > 0 && (
                        <>
                            <ModalFooter >
                                <Button onClick={handleCashout} mr={3} className="vgolf-button vgolf-height">
                                    Confirm
                                </Button>
                                <Button mr={0} onClick={() => setCashoutOpen(false)} className="vgolf-button-cancel vgolf-height">
                                    Cancel
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};

export default Index;

Index.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        points: PropTypes.string,
    }).isRequired,
};
